{
  "outcome": {
    "success": true,
    "code": null,
    "message": null,
    "description": null
  },
  "data": {
    "results": [
      {
        "idProfile": 11,
        "tsActivation": 123123141231,
        "numerUnits": 50,
        "numberOk": 3,
        "numberWarning": 4,
        "numberKO": 6,
        "numberNotAppl": 7
      },
      {
        "idProfile": 11,
        "tsActivation": 123123141231,
        "numerUnits": 50,
        "numberOk": 3,
        "numberWarning": 4,
        "numberKO": 6,
        "numberNotAppl": 7
      }
    ],
    "total": 2
  }
}