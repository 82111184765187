<s1-modal #modal [title]="unit ? ('units.fileSystem.title' | translate: { brand: unit?.nameBrand, model: unit?.nameModel, code:unit?.code }) : ('profiles.network.title.new' | translate)" (onClose)="onClose()">
  <s1-card>
    <div class="row">
      <div class="col-4">
        <b *ngIf="fileSystem?.tsUpload">{{ 'units.fileSystem.lastUpdate' | translate }}</b> {{ fileSystem?.tsUpload | s1ReadableDate: true }}
      </div>
      <div class="col-4 text-center">
        <s1-button *ngIf="isLoaded" [type]="s1ButtonType.Normal" [icon]="'fa fa-sync-alt'" (onClick)="forceMakeFileRequest()" [label]="'units.fileSystem.buttons.refreshFileSystem'"></s1-button>
        <s1-button *ngIf="!isLoaded" [type]="s1ButtonType.Normal" [disabled]="true" [label]="'units.fileSystem.buttons.waitingFileSystem'"></s1-button>
      </div>
    </div>
    <div class="row">
      <div class="col text-left tree">

        <div class="w-100 text-center">
          <div *ngIf="!isLoaded" class="mt-4 spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <tree-root #tree *ngIf="isLoaded" [nodes]="nodes" [focused]="true" [options]="customTemplateStringOptions" (toggleExpanded)="onToggleExpanded($event)">
            <ng-template #treeNodeTemplate let-node>
                <div *ngIf="node.data.directory; else fileTemplate">
                    <i class="text-info fa" [ngClass]="node.data.expanded ? 'fa-folder-open' : 'fa-folder'"></i> /{{ node.data.name }}
                </div>
                <ng-template #fileTemplate>
                  <div class="row">
                    <div class="col-8">
                      <i class="text-info far fa-file"></i> <span class="ml-2 mr-4"><strong>{{ node.data.name }}</strong> - {{ node.data.size | fileSize }}</span>
                      <i class="text-info fa fa-clock"></i> {{ getItemDate(node.data) | s1ReadableDate: true }}
                      <mdm-file-status-badge class="mx-2" *ngIf="getItemStatus(node.data) != fileItemStatus.NONE" [label]="getItemStatusLabel(node.data)" [status]="getItemStatus(node.data)"></mdm-file-status-badge>                      
                    </div>
                    <div class="col-4 text-right">
                      <s1-button *ngIf="getItemStatus(node.data) == fileItemStatus.UPLOADED" class="pr-1" [type]="s1ButtonType.Link" [icon]="'fa fa-download'" [label]="'s1.button.download'" (onClick)="download(node.data)"></s1-button>
                      <s1-button [type]="s1ButtonType.Normal" [icon]="'far fa-arrow-alt-circle-up'" [label]="'FILE REQUEST'" (onClick)="requestFile(node.data)" [disabled]="getItemStatus(node.data) == fileItemStatus.REQUESTED"></s1-button>
                    </div>
                  </div>
                </ng-template>
                
            </ng-template>
            <ng-template #loadingTemplate>{{ 'units.fileSystem.loadingData' | translate }}</ng-template>
        </tree-root>

      </div>
    </div>
    
  </s1-card>

</s1-modal>
