<s1-input-select class="col-6 mb-2 fix-margin" [control]="control" [itemsList]="agentList" 
    (onSelectClear)="cleared()" (onSelectChange)="changed($event)" [label]="'fields.input.agent'" [labelGrouped]="false" [readonly]="readonly" [bindAllObject]="bindAllObject"></s1-input-select>

<ng-container *ngIf="enabledInfo">
    <p *ngIf="deprecated" class="text-center">
        <b>{{ 'assets.agent.deprecation_msg' | translate }}</b>
    </p>

    <div class="row justify-content-end">
        <div class="col-auto text-right" *ngIf="currentDeprecationNote">
            <p class="font-weight-bold">
                {{ 'assets.agent.fields.deprecationNote' | translate }}
                <em class="mr-2 fas fa-question-circle" [tooltip]="toolTipTemplateDeprecationNote"></em>
            </p>
        </div>
        
        <div class="col-auto text-right" *ngIf="currentReleaseNote">
            <p class="font-weight-bold">
                {{ 'assets.agent.fields.releaseNote' | translate }}
                <em class="mr-2 fas fa-question-circle" [tooltip]="toolTipTemplateReleaseNote"></em>
            </p>
        </div>
    </div>
</ng-container>

<!-- TOOLTIP TEMPLATES -->

<ng-template #toolTipTemplateReleaseNote>
    <div class="text-left">
        <p>{{ currentReleaseNote }}</p>
    </div>
</ng-template>

<ng-template #toolTipTemplateDeprecationNote>
    <div class="text-left">
        <p>{{ currentDeprecationNote }}</p>
    </div>
</ng-template>