import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { S1ButtonType, S1UIService } from '@app/s1';

import { UnitStatus } from '@app/core/classes/status';
import { NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { IDispensingUnit } from '@app/shared/models/models';
import { SettingsService } from '@app/core/settings/settings.service';

export type ComponentChange<T, P extends keyof T> = {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
};

export type ComponentChanges<T> = {
  [P in keyof T]?: ComponentChange<T, P>;
};

@Component({
  selector: 'dispensing-unit-cell',
  templateUrl: './dispensing-unit-cell.component.html',
  styleUrls: ['./dispensing-unit-cell.component.scss']
})
export class DispensingUnitCellComponent implements OnChanges {

  @Input() unit: IDispensingUnit;
  @Input() template = 'units';
  @Input() showButtons = true;
  @Input() searchFilters: any;

  @Output() onOpenCobrowse = new EventEmitter<IDispensingUnit>();

  s1ButtonType = S1ButtonType;
  isOperatoreRO: boolean;
  token: string;

  constructor(private router: Router, private settings: SettingsService) {
    this.isOperatoreRO = this.settings.isOperatoreRO();
    this.token = this.settings.getCobrowseToken(this.settings.getCompany().code);
  }

  ngOnChanges(changes: ComponentChanges<DispensingUnitCellComponent>): void {
    this.unit = changes.unit?.currentValue;
  }

  stylizeStatus(status: string) {
    if (status === UnitStatus.DEAD) {
      return  'text-danger';
    } else if (status === UnitStatus.DISCONNECTED) {
      return  'text-warning';
    } else if (status === UnitStatus.DISABLED) {
      return  '';
    } else {
      return 'text-success';
    }
  }

  stylizeSinceLastStatus(timestamp: number)  {
    const now = new Date().getTime();
    let seconds = Math.floor(now - timestamp);
    seconds /= 1000;

    if (seconds && !isNaN(seconds)) {
      if (seconds >= 0 && seconds <= 720) {
        return 'text-success';
      } else {
        return 'text-danger';
      }
    }
  }

  showDetail() {
    const params: NavigationExtras = {
      queryParams: this.searchFilters,
      skipLocationChange: false,
      fragment: 'top'
    };
    const baseRoute = '/dispensing/units/detail/';
    this.router.navigate([baseRoute + this.unit.code],  {state: { filters:  params }});
    return false;
  }

  goToWall(unit: IDispensingUnit) {
    const filters = {
      idRegion: unit.idRegion,
      idSite: unit.idSite,
      idWall: unit.codWall,
      code: unit.code,
    };
    const params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top'
    };
    this.router.navigate(['/dispensing/dashboard'], {state: { filters:  params }});
  }

  openCobrowseModal(): boolean {
    this.onOpenCobrowse.emit(this.unit);
    return false;
  }

}
