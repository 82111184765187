import * as i0 from '@angular/core';
import { Injectable, forwardRef, EventEmitter, Component, ChangeDetectionStrategy, Input, Output, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgFor, NgTemplateOutlet } from '@angular/common';

/** Default values provider for rating */
const _c0 = (a0, a1) => ({
  index: a0,
  value: a1
});
function RatingComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const value_r2 = ctx.value;
    const index_r3 = ctx.index;
    i0.ɵɵtextInterpolate(index_r3 < value_r2 ? "\u2605" : "\u2606");
  }
}
function RatingComponent_ng_template_3_ng_template_3_Template(rf, ctx) {}
function RatingComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "span", 4);
    i0.ɵɵlistener("mouseenter", function RatingComponent_ng_template_3_Template_span_mouseenter_2_listener() {
      const index_r5 = i0.ɵɵrestoreView(_r4).index;
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.enter(index_r5 + 1));
    })("click", function RatingComponent_ng_template_3_Template_span_click_2_listener() {
      const index_r5 = i0.ɵɵrestoreView(_r4).index;
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.rate(index_r5 + 1));
    });
    i0.ɵɵtemplate(3, RatingComponent_ng_template_3_ng_template_3_Template, 0, 0, "ng-template", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const r_r7 = ctx.$implicit;
    const index_r5 = ctx.index;
    const ctx_r5 = i0.ɵɵnextContext();
    const star_r8 = i0.ɵɵreference(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("(", index_r5 < ctx_r5.value ? "*" : " ", ")");
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("cursor", ctx_r5.readonly ? "default" : "pointer");
    i0.ɵɵclassProp("active", index_r5 < ctx_r5.value);
    i0.ɵɵproperty("title", r_r7.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r5.customTemplate || star_r8)("ngTemplateOutletContext", i0.ɵɵpureFunction2(8, _c0, index_r5, ctx_r5.value));
  }
}
class RatingConfig {
  constructor() {
    /** aria label for rating */
    this.ariaLabel = 'rating';
  }
  static {
    this.ɵfac = function RatingConfig_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RatingConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RatingConfig,
      factory: RatingConfig.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RatingConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const RATING_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RatingComponent),
  multi: true
};
class RatingComponent {
  constructor(changeDetection, config) {
    this.changeDetection = changeDetection;
    /** number of icons */
    this.max = 5;
    /** if true will not react on any user events */
    this.readonly = false;
    /** array of icons titles, default: (["one", "two", "three", "four", "five"]) */
    this.titles = [];
    /** fired when icon selected, $event:number equals to selected rating */
    this.onHover = new EventEmitter();
    /** fired when icon selected, $event:number equals to previous rating value */
    this.onLeave = new EventEmitter();
    this.onChange = Function.prototype;
    this.onTouched = Function.prototype;
    /** aria label for rating */
    this.ariaLabel = 'rating';
    this.range = [];
    this.value = 0;
    Object.assign(this, config);
  }
  onKeydown(event) {
    if ([37, 38, 39, 40].indexOf(event.which) === -1) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const sign = event.which === 38 || event.which === 39 ? 1 : -1;
    this.rate(this.value + sign);
  }
  ngOnInit() {
    this.max = this.max || 5;
    this.titles = typeof this.titles !== 'undefined' && this.titles.length > 0 ? this.titles : [];
    this.range = this.buildTemplateObjects(this.max);
  }
  // model -> view
  writeValue(value) {
    if (value % 1 !== value) {
      this.value = Math.round(value);
      this.preValue = value;
      this.changeDetection.markForCheck();
      return;
    }
    this.preValue = value;
    this.value = value;
    this.changeDetection.markForCheck();
  }
  enter(value) {
    if (!this.readonly) {
      this.value = value;
      this.changeDetection.markForCheck();
      this.onHover.emit(value);
    }
  }
  reset() {
    if (typeof this.preValue === 'number') {
      this.value = Math.round(this.preValue);
      this.changeDetection.markForCheck();
      this.onLeave.emit(this.value);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  rate(value) {
    if (!this.readonly && this.range && value >= 0 && value <= this.range.length) {
      this.writeValue(value);
      this.onChange(value);
    }
  }
  buildTemplateObjects(max) {
    const result = [];
    for (let i = 0; i < max; i++) {
      result.push({
        index: i,
        title: this.titles[i] || i + 1
      });
    }
    return result;
  }
  static {
    this.ɵfac = function RatingComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RatingComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(RatingConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RatingComponent,
      selectors: [["rating"]],
      hostBindings: function RatingComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown", function RatingComponent_keydown_HostBindingHandler($event) {
            return ctx.onKeydown($event);
          });
        }
      },
      inputs: {
        max: "max",
        readonly: "readonly",
        titles: "titles",
        customTemplate: "customTemplate"
      },
      outputs: {
        onHover: "onHover",
        onLeave: "onLeave"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([RATING_CONTROL_VALUE_ACCESSOR]), i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 4,
      consts: [["star", ""], ["tabindex", "0", "role", "slider", "aria-valuemin", "0", 3, "mouseleave", "keydown"], ["ngFor", "", 3, "ngForOf"], [1, "sr-only", "visually-hidden"], [1, "bs-rating-star", 3, "mouseenter", "click", "title"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function RatingComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "span", 1);
          i0.ɵɵlistener("mouseleave", function RatingComponent_Template_span_mouseleave_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.reset());
          })("keydown", function RatingComponent_Template_span_keydown_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onKeydown($event));
          });
          i0.ɵɵtemplate(1, RatingComponent_ng_template_1_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(3, RatingComponent_ng_template_3_Template, 4, 11, "ng-template", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-label", ctx.ariaLabel)("aria-valuemax", ctx.range.length)("aria-valuenow", ctx.value);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngForOf", ctx.range);
        }
      },
      dependencies: [NgFor, NgTemplateOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RatingComponent, [{
    type: Component,
    args: [{
      selector: 'rating',
      providers: [RATING_CONTROL_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [NgFor, NgTemplateOutlet],
      template: "<span (mouseleave)=\"reset()\" (keydown)=\"onKeydown($event)\" tabindex=\"0\"\n      role=\"slider\" aria-valuemin=\"0\"\n      [attr.aria-label]=\"ariaLabel\"\n      [attr.aria-valuemax]=\"range.length\"\n      [attr.aria-valuenow]=\"value\">\n  <ng-template #star let-value=\"value\" let-index=\"index\">{{ index < value ? '&#9733;' : '&#9734;' }}</ng-template>\n  <ng-template ngFor let-r [ngForOf]=\"range\" let-index=\"index\">\n    <span class=\"sr-only visually-hidden\">({{ index < value ? '*' : ' ' }})</span>\n    <span class=\"bs-rating-star\"\n          (mouseenter)=\"enter(index + 1)\"\n          (click)=\"rate(index + 1)\"\n          [title]=\"r.title\"\n          [style.cursor]=\"readonly ? 'default' : 'pointer'\"\n          [class.active]=\"index < value\">\n      <ng-template [ngTemplateOutlet]=\"customTemplate || star\"\n                   [ngTemplateOutletContext]=\"{index: index, value: value}\">\n      </ng-template>\n    </span>\n  </ng-template>\n</span>\n"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: RatingConfig
  }], {
    max: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    titles: [{
      type: Input
    }],
    customTemplate: [{
      type: Input
    }],
    onHover: [{
      type: Output
    }],
    onLeave: [{
      type: Output
    }],
    onKeydown: [{
      type: HostListener,
      args: ['keydown', ['$event']]
    }]
  });
})();
class RatingModule {
  // @deprecated method not required anymore, will be deleted in v19.0.0
  static forRoot() {
    return {
      ngModule: RatingModule,
      providers: []
    };
  }
  static {
    this.ɵfac = function RatingModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RatingModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: RatingModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RatingModule, [{
    type: NgModule,
    args: [{
      imports: [RatingComponent],
      exports: [RatingComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RatingComponent, RatingConfig, RatingModule };
