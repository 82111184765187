import { Injectable } from '@angular/core';
import { S1HttpClientService, IS1SearchParams, IS1SimplePaginatedResult, S1UIService } from '@app/s1';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAsset, IGroup, ITimeline, IUnit } from '../models/models';
import { IProfileSetting, IProfileNetwork, IProfileAsset, IProfileOS } from '../models/profile';
import { IFileSystem, IFilesList, IFileItem } from '../models/file';
import { IGroupsSearchParams } from './groups.service';
import CobrowseAPI from 'cobrowse-agent-sdk';
import { IAssetsPaginated, IAssetsSearchParams } from './assets.service';
import { UntypedFormGroup } from '@angular/forms';

enum UnitAPI {
  profileSetting = "/profile/setting",
  profileSettingPending = "/profile/setting-pending",
  profileNetwork = "/profile/network",
  profileNetworkPending = "/profile/network-pending",
  profileAsset = "/profile/asset",
  profileAssetPending = "/profile/asset-pending",
  profileOs = "/profile/os",
  assets = "/assets",
  fileSystem = "/file/fileSystem",
  fileStystemRequest = "/file/fileSystem/request",
  filesList = "/file/list",
  fileRequest = "/file/request",
  download = "/file/download",
  export = "/export",
  exportFailures = "/export-disablements",
  timeline = "/timeline-mdm-disp-paginated-with-next",
  command = "/send-command",
  commandAll = "/send-command-units",
  assetsGroupBy = "/assets-with-group-by",
  unitsAssetsGroupBy = "/units-from-group-by",
  remoteControl = "/remoteControl",
}

export interface IUnitsSearchParams extends IS1SearchParams {
  id?: number,
  codUnit: string,
  fingerprint?: string,
  idCompany?: number,
  idAgent?: number,
  idType?: number,
  idProfile?: number,
  idRegion?: number,
  idSite?: number,
  idGroup?: number,
  idBrand?: number,
  idModel?: number,
  profileStatus?: string,
  lastStatus?: string,
  unitStatus?: string,
  osStatus?: string,
  batteryLevel?: string,
  batteryHealth?: string,
  ipaddress?: string,
  showOnlyDisabledUnit?: boolean,
  ae?: boolean,
  failure?: boolean,
  disabledDataFrom?: number,
  disabledDataTo?: number,
  enabledDataFrom?: number,
  enabledDataTo?: number,
  note?: string,
  osRelease?: string
}

export interface IUnitFileRequestParams {
  path: string,
  name: string,
  tsLastModified: number
}

export interface IUnistGetFileSystemParams extends ITimestampUpdate { }

export interface IUnitsPaginated extends IS1SimplePaginatedResult {
  units: IUnit[]
}

export interface ITimestampUpdate {
  tsMinUpdate: number
}

export enum UnitRemoteConnectionActions {
  Start = "start",
  Stop = "stop"
}

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  private path = "/units"
  private pathFailures = "/units/disablements"
  private pathDetail = "/units/details"
  private pathGroups = "/units/groups"
  private pathAddGroup = "/units/add-group"
  private pathDisable = "/disable"
  private pathUpdateNote = "/add-note";
  private cobrowse;

  constructor(private s1HttpClient: S1HttpClientService, private ui: S1UIService) { }

  getUnit(parameters: IUnitsSearchParams, ui: boolean = true): Observable<IUnit> {
    return this.s1HttpClient.get(this.pathDetail, parameters, ui).pipe(
      map(response => response.data)
    );
  }

  getUnits(parameters: IUnitsSearchParams, ui: boolean = true): Observable<IUnitsPaginated> {
    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => { return { units: response.data.results, total: response.data.total } })
    );
  }

  getUnitsFaults(parameters: IUnitsSearchParams, ui: boolean = true): Observable<IUnitsPaginated> {
    return this.s1HttpClient.get(this.pathFailures, parameters, ui).pipe(
        map(response => { return { units: response.data.results, total: response.data.total } })
    );
  }

  downloadSearchResult(parameters: IUnitsSearchParams): Observable<any> {
    return this.s1HttpClient.download(this.path + UnitAPI.export, parameters).pipe(
      map(file => {
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
      })
    )
  }

  downloadFailuresSearchResult(parameters: IUnitsSearchParams): Observable<any> {
    return this.s1HttpClient.download(this.path + UnitAPI.exportFailures, parameters).pipe(
        map(file => {
          const url = URL.createObjectURL(file);
          window.open(url, '_blank');
        })
    )
  }

  disableUnit(idUnit: number, reason: any, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.post(this.path + "/" + idUnit + this.pathDisable, reason, ui).pipe(
      map(response => response.data)
    );
  }

  getUnitGroups(parameters?: IGroupsSearchParams, ui: boolean = true): Observable<IGroup[]> {
    return this.s1HttpClient.get(this.pathGroups, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  updateGroup(idUnit: number, idGroup:number, ui: boolean = true): Observable<IGroup> {
    return this.s1HttpClient.post(this.pathAddGroup + "?idUnit=" + idUnit + "&idGroup=" + idGroup, ui).pipe(
      map(response => response.data)
    );
  }

  getUnitTimeline(parameters: any, ui: boolean): Observable<any> {
    return this.s1HttpClient.get(this.path + UnitAPI.timeline, parameters, ui).pipe(
      map(response => response.data)
    )
  }

  getUnitProfileSetting(unit: IUnit): Observable<IProfileSetting> {
    return this.s1HttpClient.get(this.elementPath(unit) + UnitAPI.profileSetting).pipe(
      map(response => response.data)
    )
  }

  getUnitProfileNetwork(unit: IUnit): Observable<IProfileNetwork> {
    return this.s1HttpClient.get(this.elementPath(unit) + UnitAPI.profileNetwork).pipe(
      map(response => response.data)
    );
  }

  getUnitProfileAsset(unit: IUnit): Observable<IProfileAsset> {
    return this.s1HttpClient.get(this.elementPath(unit) + UnitAPI.profileAsset).pipe(
      map(response => response.data)
    );
  }

  getUnitProfileOs(unit: IUnit): Observable<IProfileOS> {
    return this.s1HttpClient.get(this.elementPath(unit) + UnitAPI.profileOs).pipe(
      map(response => response.data)
    );
  }

  getUnitFileSystem(unit: IUnit, parameters?: IUnistGetFileSystemParams): Observable<IFileSystem> {
    return this.s1HttpClient.get(this.elementPath(unit) + UnitAPI.fileSystem, parameters, false).pipe(
      map(response => response.data)
    );
  }

  getUnitAssets(unit: IUnit): Observable<IAsset[]> {
    return this.s1HttpClient.get(this.elementPath(unit) + UnitAPI.assets).pipe(
      map(response => response.data.results)
    )
  }

  getUnitAssetsGroupBy(searchParams: IS1SearchParams, parameters: IAssetsSearchParams,  isNotGrouped: boolean, ui: boolean = true): Observable<IAssetsPaginated> {
    return this.s1HttpClient.get(this.path + UnitAPI.assetsGroupBy,  {...searchParams, ...parameters}, ui).pipe(
      map(response => { 
        let results =  isNotGrouped ? response.data.results[0]?.unitAssets : response.data.results;
        return { assets: results, total: response.data.total } 
      })
    );
  }

  getAssetUnits(parameters: any, ui: boolean = true): Observable<IUnit[]> {
    return this.s1HttpClient.get(this.path + UnitAPI.unitsAssetsGroupBy, parameters, ui).pipe(
      map(response => response.data.results)
    );
  }

  sendCommand(idCompany: number, codeUnit: string, command: string): Observable<boolean> {
    return this.s1HttpClient.post(this.path + UnitAPI.command + "?idCompany=" + idCompany + "&codUnit=" + codeUnit + "&command=" + command, {}).pipe(
      map(response => response.outcome.success)
    );
  }

  sendCommandAll(idCompany: number, units: string[], command: string): Observable<boolean> {
    let command_units = {
      idCompany: idCompany,
      command: command,
      units: units
    }; 
    return this.s1HttpClient.post(this.path + UnitAPI.commandAll, command_units).pipe(
      map(response => response.outcome.success)
    );
  }

  requestUnitFileSystem(unit: IUnit): Observable<ITimestampUpdate> {
    return this.s1HttpClient.post(this.elementPath(unit) + UnitAPI.fileStystemRequest, {}, false).pipe(
      map(response => response.data)
    );
  }

  requestUnitFile(unit: IUnit, parameters: IUnitFileRequestParams): Observable<boolean> {
    return this.s1HttpClient.post(this.elementPath(unit) + UnitAPI.fileRequest, parameters, true).pipe(
      map(response => true)
    );
  }

  downloadUnitFile(fileID: number, filename: string): Observable<boolean> {
    return this.s1HttpClient.download(this.path + "/" + fileID + UnitAPI.download).pipe(
      map(file => { 

        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.download = filename;
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
        
        return true 
      
      })
    )
  }

  getCobrowse() {
    if(!this.cobrowse) {
      this.cobrowse = new CobrowseAPI();
    }
    return this.cobrowse;
  }

  updateNote(idUnit: number, note: any, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.post(this.path + "/" + idUnit + this.pathUpdateNote, note, ui).pipe(
      map(response => response.data)
    );
  }

  saveNote(detailForm: UntypedFormGroup, companyUnitCustomFields: any[], mdmId: number): Promise<boolean> {
    return new Promise<boolean>((res) => {
      const err = this.validateDetailsForm(detailForm, companyUnitCustomFields);
      
      if (!err) {
        res(true);
      } else {
        const note: any = {
          customField1: detailForm?.controls.UNIT_CUSTOM_FIELD_1.value,
          customField2: detailForm?.controls.UNIT_CUSTOM_FIELD_2.value,
          customField3: detailForm?.controls.UNIT_CUSTOM_FIELD_3.value,
          customField4: detailForm?.controls.UNIT_CUSTOM_FIELD_4.value,
          customField5: detailForm?.controls.UNIT_CUSTOM_FIELD_5.value,
          customField6: detailForm?.controls.UNIT_CUSTOM_FIELD_6.value
        };

        this.updateNote(mdmId, note).subscribe(_ => {
          this.ui.showSuccessToast('msg.operation_ok');
          res(false);
        });
      }
    });
  }

  manageRemoteControl(idUnit: number, action: string): Observable<boolean> {
    return this.s1HttpClient.post(`${this.path}/${idUnit}${UnitAPI.remoteControl}/${action}`, null, true).pipe(
      map(response => response.outcome.success)
    );
  }

  // ---------- PRIVATE METHODS ---------- //

  private elementPath(unit: IUnit): string {
    return this.path + "/" + unit.id
  }

  private validateDetailsForm(detailForm: UntypedFormGroup, companyUnitCustomFields: any[]) {
    let hasError = false;

    detailForm?.controls.UNIT_CUSTOM_FIELD_1.setErrors(null);
    detailForm?.controls.UNIT_CUSTOM_FIELD_2.setErrors(null);
    detailForm?.controls.UNIT_CUSTOM_FIELD_3.setErrors(null);
    detailForm?.controls.UNIT_CUSTOM_FIELD_4.setErrors(null);
    detailForm?.controls.UNIT_CUSTOM_FIELD_5.setErrors(null);
    detailForm?.controls.UNIT_CUSTOM_FIELD_6.setErrors(null);

    let regex = new RegExp(companyUnitCustomFields['UNIT_CUSTOM_FIELD_1'].regex);
    let valueInput = detailForm?.controls.UNIT_CUSTOM_FIELD_1.value;
    let errorMesg = companyUnitCustomFields['UNIT_CUSTOM_FIELD_1'].errorMessage;
    if(valueInput == null) valueInput = '';
    if(!regex.test(valueInput)){
      detailForm?.controls.UNIT_CUSTOM_FIELD_1.setErrors({msgError: errorMesg});
      detailForm?.controls.UNIT_CUSTOM_FIELD_1.markAsTouched();
      hasError = true;
    }
    regex = new RegExp(companyUnitCustomFields['UNIT_CUSTOM_FIELD_2'].regex);
    valueInput = detailForm?.controls.UNIT_CUSTOM_FIELD_2.value;
    if(valueInput == null) valueInput = '';
    errorMesg = companyUnitCustomFields['UNIT_CUSTOM_FIELD_2'].errorMessage;
    if(!regex.test(valueInput)){
      detailForm?.controls.UNIT_CUSTOM_FIELD_2.setErrors({msgError: errorMesg});
      detailForm?.controls.UNIT_CUSTOM_FIELD_2.markAsTouched();
      hasError = true;
    }
    regex = new RegExp(companyUnitCustomFields['UNIT_CUSTOM_FIELD_3'].regex);
    valueInput = detailForm?.controls.UNIT_CUSTOM_FIELD_3.value;
    if(valueInput == null) valueInput = '';
    errorMesg = companyUnitCustomFields['UNIT_CUSTOM_FIELD_3'].errorMessage;
    if(!regex.test(valueInput)){
      detailForm?.controls.UNIT_CUSTOM_FIELD_3.setErrors({msgError: errorMesg});
      detailForm?.controls.UNIT_CUSTOM_FIELD_3.markAsTouched();
      hasError = true;
    }
    regex = new RegExp(companyUnitCustomFields['UNIT_CUSTOM_FIELD_4'].regex);
    valueInput = detailForm?.controls.UNIT_CUSTOM_FIELD_4.value;
    if(valueInput == null) valueInput = '';
    errorMesg = companyUnitCustomFields['UNIT_CUSTOM_FIELD_4'].errorMessage;
    if(!regex.test(valueInput)){
      detailForm?.controls.UNIT_CUSTOM_FIELD_4.setErrors({msgError: errorMesg});
      detailForm?.controls.UNIT_CUSTOM_FIELD_4.markAsTouched();
      hasError = true;
    }
    regex = new RegExp(companyUnitCustomFields['UNIT_CUSTOM_FIELD_5'].regex);
    valueInput = detailForm?.controls.UNIT_CUSTOM_FIELD_5.value;
    if(valueInput == null) valueInput = '';
    errorMesg = companyUnitCustomFields['UNIT_CUSTOM_FIELD_5'].errorMessage;
    if(!regex.test(valueInput)){
      detailForm?.controls.UNIT_CUSTOM_FIELD_5.setErrors({msgError: errorMesg});
      detailForm?.controls.UNIT_CUSTOM_FIELD_5.markAsTouched();
      hasError = true;
    }
    regex = new RegExp(companyUnitCustomFields['UNIT_CUSTOM_FIELD_6'].regex);
    valueInput = detailForm?.controls.UNIT_CUSTOM_FIELD_6.value;
    if(valueInput == null) valueInput = '';
    errorMesg = companyUnitCustomFields['UNIT_CUSTOM_FIELD_6'].errorMessage;
    if(!regex.test(valueInput)){
      detailForm?.controls.UNIT_CUSTOM_FIELD_6.setErrors({msgError: errorMesg});
      detailForm?.controls.UNIT_CUSTOM_FIELD_6.markAsTouched();
      hasError = true;
    }

    return !hasError;
  }

}
