import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { IGroup, IUnit } from '@app/shared/models/models';
import { UnitsService } from '@app/shared/services/units.service';
import { S1ButtonType, S1UIService } from '@app/s1';
import { ProfileStatus, UnitStatus } from '@app/core/classes/status';
import { NavigationExtras, Router } from '@angular/router';
import { SettingsService } from '@app/core/settings/settings.service';

export type ComponentChange<T, P extends keyof T> = {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
};

export type ComponentChanges<T> = {
  [P in keyof T]?: ComponentChange<T, P>;
};

@Component({
  selector: 'mdm-unit-cell',
  templateUrl: './unit-cell.component.html',
  styleUrls: ['./unit-cell.component.scss']
})
export class UnitCellComponent implements OnChanges {
  
  @Input() unit: IUnit;
  @Input() template: string = 'units';
  @Input() showButtons: boolean = true;
  @Input() searchFilters: any;
  @Input() ae: boolean = false;
  @Input() modal: boolean = false;
  
  @Output() unitRemoved = new EventEmitter<number>();
  @Output() onChangeUnitProfileStatusInError = new EventEmitter<number>();
  @Output() unitDisabled = new EventEmitter<any>();
  @Output() onOpenProfileSettings = new EventEmitter<IUnit>();
  @Output() onOpenProfileSettingsPending = new EventEmitter<IUnit>();
  @Output() onOpenProfileNetwork = new EventEmitter<IUnit>();
  @Output() onOpenProfileNetworkPending = new EventEmitter<IUnit>();
  @Output() onOpenProfileAsset = new EventEmitter<IUnit>();
  @Output() onOpenProfileAssetPending = new EventEmitter<IUnit>();
  @Output() onOpenFileSystem = new EventEmitter<IUnit>();
  @Output() onOpenAssets = new EventEmitter<IUnit>();
  @Output() onOpenCobrowse = new EventEmitter<IUnit>();
  @Output() onOpenAskDisable = new EventEmitter<IUnit>();

  s1ButtonType = S1ButtonType;
  profileStatus = ProfileStatus;
  isOperatoreRO: boolean;
  isDisabled: boolean = false;
  token: string;

  constructor(
    private settings: SettingsService,
    private router: Router,
    private ui: S1UIService,
    private unitsService: UnitsService
  ) {
    this.isOperatoreRO = this.settings.isOperatoreRO();
    this.token = this.settings.getCobrowseToken(this.settings.getCompany().code);
  }
  
  ngOnChanges(changes: ComponentChanges<UnitCellComponent>): void {
    this.unit = changes.unit?.currentValue;
    this.isDisabled = this.unit?.status == UnitStatus.DISABLED;
  }

  openProfileSettings(): boolean {
    if(this.unit.idSettingsProfile) {
      this.onOpenProfileSettings.emit(this.unit);
    }
    return false;
  }
  openProfileSettingsPending(): boolean {
    if(this.unit.idSettingsProfilePending) {
      this.onOpenProfileSettingsPending.emit(this.unit);
    }
    return false;
  }

  openProfileNetwork(): boolean {
    if(this.unit.idNetworkProfile) {
      this.onOpenProfileNetwork.emit(this.unit);
    }
    return false;
  }
  openProfileNetworkPending(): boolean {
    if(this.unit.idNetworkProfilePending) {
      this.onOpenProfileNetworkPending.emit(this.unit);
    }
    return false;
  }

  openProfileAsset(): boolean {
    if(this.unit.idAssetsProfile) {
      this.onOpenProfileAsset.emit(this.unit);
    }
    return false;
  }
  openProfileAssetPending(): boolean {
    if(this.unit.idAssetsProfilePending) {
      this.onOpenProfileAssetPending.emit(this.unit);
    }
    return false;
  }

  openFileSystem(): boolean {
    this.onOpenFileSystem.emit(this.unit);
    return false;
  }

  openAssetsModal(): boolean {
    this.onOpenAssets.emit(this.unit);
    return false;
  }

  openCobrowseModal(): boolean {
    this.onOpenCobrowse.emit(this.unit);
    return false;
  }

  openAskDisableModal(): boolean {
    this.onOpenAskDisable.emit(this.unit);
    return false;
  }

  stylizeStatus(status: string) {
    if (status === UnitStatus.DEAD || status === UnitStatus.DISCONNECTED) {
      return  'text-danger';
    } if (status === UnitStatus.DISABLED) {
      return  '';
    } else {
      return 'text-success';
    }
  }

  stylizeProfileStatus(status: string) {
    if (status === ProfileStatus.APPLIED) {
      return 'text-success';
    } else if (status === ProfileStatus.DOWNLOAD || status === ProfileStatus.ASSIGNED || status === ProfileStatus.SENT) {
      return 'text-warning';
    } else if (status === ProfileStatus.ERROR) {
      return 'text-danger';
    } else {
      return 'text-muted';
    }
  }

  stylizeSinceLastStatus(seconds: number)  {
    if (seconds && !isNaN(seconds)) {
      if (seconds >= 0 && seconds <= 600) {
        return 'text-success';
      } else {
        return 'text-danger';
      }
    }
  }

  stylizeProfile(idProfile: number, idProfilePending: number, status: string) {
    let classes = '';
    if (status === ProfileStatus.APPLIED) {
      classes = 'pointer text-success';
    } else if (status === ProfileStatus.DOWNLOAD || status === ProfileStatus.ASSIGNED || status === ProfileStatus.SENT) {
      classes = 'pointer text-warning';
    } else if (status === ProfileStatus.CANCELED || status === ProfileStatus.INTERRUPTED) {
      classes = 'pointer text-muted';
    } else if (status === ProfileStatus.ERROR) {
      classes = 'pointer text-danger';
    } else {
      if(!idProfile && idProfilePending) {
        classes = 'no-pointer text-muted';
      }
    }
    return classes;
  }

  showDetail() {
    let params: NavigationExtras = {
      queryParams: this.searchFilters,
      skipLocationChange: false,
      fragment: 'top' 
    };
    //let baseRoute = !this.ae ? '/units/detail/' : '/aeunits/detail/';
    let baseRoute = '/units/detail/';
    this.router.navigate([baseRoute + this.unit.code],  {state: { filters:  params }});
    return false;
  }

  askRemove(unit: IUnit) {
    this.ui.showDialogPopup("profiles.distributions.popup.askRemove").then(result => {
      if (result.value) {
        this.unitRemoved.emit(unit.id);
      }
    });
  }

  askChangeProfileStatus(unit: IUnit) {
    if(unit?.profileStatus ===  ProfileStatus.ASSIGNED || unit?.profileStatus ===  ProfileStatus.DOWNLOAD || unit?.profileStatus ===  ProfileStatus.SENT) {
      let text = 'profiles.distributions.popup.askChangeProfileStatusToCanceled'; //ProfileStatus.ASSIGNED
      if(unit?.profileStatus ===  ProfileStatus.DOWNLOAD) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToInterrupted';
      } else if(unit?.profileStatus ===  ProfileStatus.SENT) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToAborted';
      }
      this.ui.showDialogPopup(text).then(result => {
        if (result.value) {
          this.onChangeUnitProfileStatusInError.emit(unit.idUnitProfile);
        }
      });
    }
  }

  updateGroup(group: IGroup) {

    this.unitsService.updateGroup(this.unit.id, group.id).subscribe(group => {
      this.ui.showSuccessToast('msg.operation_ok');
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([this.router.url]);
    });
  }

  goToUnits(unit: IUnit) {
    let filters = {
      code: unit.code
    };
    let params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top' 
    };
    //let baseRoute = !this.ae ? '/units' : '/aeunits';
    let baseRoute = '/units';
    this.router.navigate([baseRoute],  {state: { filters:  params }});
  }

}
