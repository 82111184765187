<ng-container>

    <div class="mt-1 row justify-content-center"
        style="padding: 0 0.55rem;">
        <div class="col-12 col-lg-6">
            <statistic-card [title]="'dispensing.home.charts.kiosks'" [badges]="badgesKiosk" [chart]="pieKiosk" [options]="kioskOptions" [padding]="4" (chartClicked)="pieClicked(unitType.KIOSK, $event)"></statistic-card>
        </div>
        <div class="col-12 col-lg-6">
            <statistic-card [title]="'dispensing.home.charts.units'" [badges]="badgesUnit" [chart]="pieUnit" [options]="unitOptions" [padding]="4" (chartClicked)="pieClicked(unitType.UNIT, $event)"></statistic-card>
        </div>
    </div>

    <div class="row mt-3">
        <div class="d-flex align-items-center justify-content-center col-12 text-center text-primary font-weight-bold">
            <div style="cursor: pointer;" (click)="getSitesUnitsInfo()">
                <span>{{ (showMore ? 'dashboard.show_less' : 'dashboard.show_more') | translate }}</span>
                <em class="ml-2 fas" [ngClass]="showMore ? 'fa-angle-up' : 'fa-angle-down'"></em>
            </div>
        </div>
    </div>

    <div *ngIf="showMore">
        <!-- UNITS -->
        <div class="row mt-2 px-4" *ngIf="chartUnitsConfig">
            <div class="col-12 p-0 text-center">
                <div class="row align-items-center h-100">
                    <div class="col-12 col-md-12 col-xl-12 d-flex flex-column">
                        <mdm-dashboard-summary-card
                            [config]="chartUnitsConfig"
                            [plotType]="chartUnitsPlotType"
                            [hasModal]="unitsList?.length > 10"
                            (onDetailPlotLink)="unitAction($event, false)"
                            (onModalLink)="unitAction($event, true)">
                        </mdm-dashboard-summary-card>
                    </div>
                </div>
            </div>
        </div>

        <!-- PRELIEVI -->
        <div class="row mt-1 px-4" *ngIf="chartPrelieviConfig">
            <div class="col-12 p-0 text-center">
                <div class="row align-items-center h-100">
                    <div class="col-12 col-md-12 col-xl-12 d-flex flex-column">
                        <mdm-dashboard-summary-card
                            [config]="chartPrelieviConfig"
                            [plotType]="chartPrelieviPlotType"
                            [hasModal]="prelieviList?.length > 10"
                            [hasDateManagement]="true"
                            (onDetailPlotLink)="prelieviAction($event)"
                            (onModalLink)="prelieviAction($event)"
                            (onDateChange)="loadChart($event)">
                        </mdm-dashboard-summary-card>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
