import { Component, Output, EventEmitter, ViewChild, TemplateRef, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

export interface IS1Modal {
  modal: S1Modal;
  close()
}

export enum S1ModalSizes {
  SM = 'modal-sm',
  MD = '',
  LG = 'modal-lg',
  XL = 'modal-xl'
}

@Component({
  selector: 's1-modal',
  templateUrl: './s1-modal.component.html',
  styleUrls: ['./s1-modal.component.scss']
})
export class S1Modal implements OnDestroy {

  @Input() title: string;
  @Input() bodyStyleClass: string = "overflow-auto";
  @Output() onClose = new EventEmitter();

  @ViewChild('modalTemplate') public modalTemplate: TemplateRef<any>;
  modalRef: BsModalRef;

  hiddenSubscription: Subscription;

  constructor(private modalService: BsModalService) { }

  ngOnDestroy(): void {
    this.hiddenSubscription?.unsubscribe();
  }

  open(size: S1ModalSizes = S1ModalSizes.MD) {
    this.modalRef = this.modalService.show(this.modalTemplate, { class: size });

    this.hiddenSubscription = this.modalRef.onHidden.subscribe(_ => {
      this.onClose.emit();
    })
  }

  close() {
    this.modalRef.hide();
  }

}