import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-button-check',
  templateUrl: './s1-button-check.component.html',
  styleUrls: ['./s1-button-check.component.scss']
})
export class S1ButtonCheck implements OnInit {

  @Input() check: boolean = false
  @Output() checkChange = new EventEmitter<boolean>()
  @Input() text : string
  @Input() disabled : boolean = false
  @Input() readonly : boolean = true
  @Input() classToApply : string = ''

  constructor() { }

  ngOnInit(): void { }

  toggle() {
    this.check = !this.check
    this.checkChange.emit(this.check)
  }

}
