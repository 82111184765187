import { Component, OnInit, ViewChild } from '@angular/core';
import { S1Modal, IS1Modal, S1ModalSizes, S1ButtonType, S1UIService } from '@app/s1';
import { IUnit } from '@app/shared/models/models';
import { FileItemStatus, IFileItem, IFileSystem, IFileSystemItem } from '@app/shared/models/file';
import { IUnitFileRequestParams, UnitsService } from '@app/shared/services/units.service';
import { ColorsService } from '@app/shared/colors/colors.service';
import * as _ from 'lodash';
import { TreeComponent } from '@bugsplat/angular-tree-component';

@Component({
  selector: 'mdm-unit-filesystem-modal',
  templateUrl: './unit-filesystem-modal.component.html',
  styleUrls: ['./unit-filesystem-modal.component.scss']
})
export class UnitFilesystemModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('tree') tree: TreeComponent;

  unit?: IUnit;
  nodes: any[];
  expanded: string[] = [];
  fileSystem: IFileSystem;
  filesList: IFileItem[];

  s1ButtonType = S1ButtonType;
  fileItemStatus = FileItemStatus;
  isLoaded = false;
  filesRequested: IFileSystemItem[];

  // polling variables
  counter: number;
  limit: number;
  timeout: number;

  customTemplateStringOptions = {
    isExpandedField: 'expanded',
    idField: 'id',
    allowDrag: false,
    childrenField: 'subDirectory'
  };

  timerPollingFileSystem: any;
  timerPollingFileList: any;

  currenFileSystem: string;

  constructor(private unitsService: UnitsService, public colors: ColorsService, private ui: S1UIService) {
    this.filesRequested = [];
    this.resetPolling();
  }

  ngOnInit(): void { }

  open(unit?: IUnit) {
    this.filesRequested = [];
    this.unit = unit;
    this.modal.open(S1ModalSizes.XL);

    setTimeout(() => {
      this.getFileSystem();
    }, 1);
  }

  private resetPolling() {
    this.counter = 0;
    this.limit = 12;
    this.timeout = 5000;
    this.isLoaded = false;
  }

  onToggleExpanded(event) {
    const node = event.node.data.path + '/' + event.node.data.name;
    const index = this.expanded.indexOf(node);
    if (event.isExpanded && index === -1) {
      this.expanded.push(node);
    }
    if (!event.isExpanded && index !== -1) {
      this.expanded.splice(index, 1);
    }
  }

  isFileSystemChanged(oldFileSystem: any, newFileSystem: any) {
    return oldFileSystem !== newFileSystem;
  }

  viewNodesExpanded() {
    this.expanded.forEach(path => {
      const treeNode = this.tree.treeModel.getNodeBy(node => {
        return node.data.path + '/' + node.data.name === path;
      });

      if (treeNode) {
        treeNode.expand();
      }
    });
  }

  private updateTree() {
    if (this.isFileSystemChanged(this.currenFileSystem, JSON.stringify(this.fileSystem.fsTree))) {
      this.expanded = [];
      this.currenFileSystem = JSON.stringify(this.fileSystem.fsTree);
      this.nodes = this.fileSystem.fsTree;
    } else {
      setTimeout(() => {
        this.viewNodesExpanded();
      }, 1);
    }

    this.filesList = this.fileSystem.fileList;
    this.filesRequested = [];
    this.isLoaded = true;
  }

  getFileSystem() {
    if (this.unit === null) { return; }

    this.unitsService.getUnitFileSystem(this.unit).subscribe(fileSystem => {
      if (fileSystem.fsTree) {
        this.fileSystem = fileSystem;
        this.updateTree();
        this.pollingFileList();
        console.log('FS [' + this.unit.code + '] requested filesystem... COMPLETED');
      } else {
        if (this.counter > 0) {
          this.pollingFileSystem();
        } else {
          this.makeFilesRequest();
        }
      }
    });
  }

  private pollingFileSystem() {
    if (this.counter < this.limit) {
      this.counter++; // move counter
      this.timerPollingFileSystem = setTimeout(() => {
        this.getFileSystem();
      }, this.timeout);
      console.log('FS [' + this.unit.code + '] requested filesystem... POLLING(' + this.counter + ')');
    } else {
      this.isLoaded = true;
      console.log('FS [' + this.unit.code + '] requested filesystem... NO RESPONSE');
    }
  }

  private makeFilesRequest() {
    if (this.unit === null) { return; }

    this.resetPolling();
    this.unitsService.requestUnitFileSystem(this.unit).subscribe(timestamp => {
      this.pollingFileSystem();
    });
  }

  forceMakeFileRequest() {
    console.log('FS [' + this.unit.code + '] requested filesystem... RESTART');
    this.makeFilesRequest();
  }

  private getFileList() {
    if (this.unit === null) { return; }

    this.unitsService.getUnitFileSystem(this.unit).subscribe(fileSystem => {
      if (fileSystem.fsTree) {
        this.fileSystem = fileSystem;
        this.updateTree();
        console.log('FS [' + this.unit.code + '] filelist... refresh');
      }
    });
  }

  private pollingFileList() {
    if (this.unit === null) { return; }

    clearTimeout(this.timerPollingFileList);
    this.timerPollingFileList = setInterval(() => {
      this.getFileList();
    }, this.timeout);
  }

  private findFileItem(item: IFileSystemItem): IFileItem {
    return this.filesList?.find(itemInList => {
      return itemInList.path === item.path && itemInList.name === item.name;
    });
  }

  isFileRequested(item: IFileSystemItem): FileItemStatus {
    if (this.filesRequested.length === 0) {
      return null;
    }
    const found = this.filesRequested.find(file => {
      return file.path === item.path && file.name === item.name;
    });
    if (!!found) {
      return FileItemStatus.REQUESTED;
    } else {
      return null;
    }
  }

  getItemDate(item: IFileSystemItem): Date {
    const itemFound = this.findFileItem(item);
    const date = new Date(itemFound?.tsLastModified ?? item.tsLastModified);
    return date;
  }

  getItemStatus(item: IFileSystemItem): FileItemStatus {
    const itemFound = this.findFileItem(item);
    return this.isFileRequested(item) || (itemFound?.status ?? FileItemStatus.NONE);
  }

  getItemStatusLabel(item: IFileSystemItem): string {
    const itemFound = this.findFileItem(item);
    const status = itemFound?.status ?? FileItemStatus.NONE;
    if (status === FileItemStatus.UPLOADED) {
      return 'READY TO DOWNLOAD';
    }
  }

  requestFile(item: IFileSystemItem) {
    const params: IUnitFileRequestParams = {
      path: item.path,
      name: item.name,
      tsLastModified: item.tsLastModified
    };
    if (this.unit == null) { return; }

    this.filesRequested.push(item);
    this.unitsService.requestUnitFile(this.unit, params).subscribe(requested => {
      if (requested) {
        this.ui.showSuccessToast('units.fileSystem.popup.fileRequested');
      } else {
        this.ui.showErrorPopup('units.fileSystem.popup.fileRequestError');
      }
    });
  }

  download(item: IFileSystemItem) {
    const itemFound = this.findFileItem(item);
    if (itemFound) {
      this.unitsService.downloadUnitFile(itemFound?.id, itemFound?.name).subscribe(downloaded => {
        if (!downloaded) {
          this.ui.showErrorPopup('units.fileSystem.popup.downloadError');
        }
      });
    }
  }

  onClose() {
    clearTimeout(this.timerPollingFileList);
    this.isLoaded = false;
  }

  close() {
    this.modal.close();
  }

}
