"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.closestXdocParent = closestXdocParent;
var _isxdoc = require("./isxdoc.js");
function closestXdocParent() {
  var parent = window;
  do {
    parent = parent.parent;
    if ((0, _isxdoc.isxdoc)(parent)) return parent;
  } while (parent !== parent.parent);
  return null;
}