{
  "outcome": {
    "success": true,
    "code": null,
    "message": null,
    "description": null
  },
  "data": {
    "results": [
      {
        "idProfile": 11,
        "id": 1,
        "idCompany": 7,
        "nameCompany": "Esselunga",
        "idRegion": 1,
        "nameRegion": "Region 1",
        "idSite": 1,
        "nameSite": "Site1",
        "idBrand": 7,
        "nameBrand": "Zebra",
        "idModel": 1,
        "nameModel": "TC20",
        "idUnit": 6,
        "codUnit": "GT204560",
        "ipaddress": "192.168.0.1",
        "btmac": "ee:::add:1",
        "wifimac": "ee:::add:1",
        "batterySerialNumber": "ABCDE",
        "batteryPerc": 50,
        "appRelease": "1.0.0 (1)",
        "osRelease": "1.0.0.0.1",
        "tsLastStatus": 12131231231231,
        "tsLastEvent": 12131231231231,
        "profileStatus": "ACTIVATED"
      },
      {
        "idProfile": 11,
        "id": 2,
        "idCompany": 7,
        "nameCompany": "Esselunga",
        "idRegion": 1,
        "nameRegion": "Region 1",
        "idSite": 1,
        "nameSite": "Site1",
        "idBrand": 7,
        "nameBrand": "Zebra",
        "idModel": 1,
        "nameModel": "TC20",
        "idUnit": 6,
        "codUnit": "GT204560",
        "ipaddress": "192.168.0.1",
        "btmac": "ee:::add:1",
        "wifimac": "ee:::add:1",
        "batterySerialNumber": "ABCDE",
        "batteryPerc": 50,
        "appRelease": "1.0.0 (1)",
        "osRelease": "1.0.0.0.1",
        "tsLastStatus": 12131231231231,
        "tsLastEvent": 12131231231231,
        "profileStatus": "ERROR"
      }
    ],
    "total": 2
  }
}