import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-textarea',
  templateUrl: './s1-input-textarea.component.html',
  styleUrls: ['./s1-input-textarea.component.scss']
})
export class S1InputTextArea implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() inputClass: string;
  @Input() label: string = '';
  @Input() subtext: string;
  @Input() labelGrouped: boolean = false;
  @Input() readonly: boolean = false;
  @Input() isValid: boolean = true;
  @Output() onChange = new EventEmitter<any>();

  @ViewChild('inputEl') inputEl: ElementRef;

  constructor() { }

  ngOnInit(): void { }

  checkIsValid(): boolean {
    return this.control && (this.control.valid || !this.control.touched);
  }

  getFocused() {

    setTimeout(()=> {
      this.inputEl?.nativeElement.focus();
    }, 1);
    
  }

  onInputChange(value) {
    this.onChange.emit(value);
  }

}
