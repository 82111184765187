import { Component, ElementRef, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SettingsService } from "@app/core/settings/settings.service";
import { IS1Modal, S1ButtonType, S1HttpClientService, S1Modal, S1ModalSizes, S1UIService } from "@app/s1";
import { IDispensingUnit, IUnit } from "@app/shared/models/models";
import { UnitRemoteConnectionActions, UnitsService } from "@app/shared/services/units.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: 'mdm-unit-cobrowse-modal',
  templateUrl: './unit-cobrowse-modal.component.html',
  styleUrls: ['./unit-cobrowse-modal.component.scss']
})
export class UnitCobrowseModalComponent implements IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('iframe') public iframe: ElementRef;

  unit?: IUnit | IDispensingUnit;
  token: string;
  cobrowse: any;
  device: any;
  ctxCobrowse: any;
  iframeUrl: SafeResourceUrl;
  isLoaded: boolean;
  s1ButtonType = S1ButtonType;

  constructor(
    private unitsService: UnitsService,
    private ui: S1UIService,
    private translate: TranslateService,
    private settings: SettingsService,
    private sanitizer: DomSanitizer,
    private s1HttpClient: S1HttpClientService
  ) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('javascript:false;');
    this.token = this.settings.getCobrowseToken(this.settings.getCompany().code);
    this.cobrowse = this.unitsService.getCobrowse();
    this.cobrowse.token = this.token;
  }

  open(unit?: IUnit | IDispensingUnit) {
    this.unit = unit;
    this.startConnection(unit);
  }

  close() {
    this.stopConnection(this.unit.id);
  }

  refresh() {
    this.startConnection(this.unit, true);
  }

  // ---------- PRIVATE METHODS ---------- //

  private startConnection(unit: IUnit | IDispensingUnit, refresh?: boolean): void {
    this.isLoaded = false;
    this.unitsService.manageRemoteControl(unit.id, UnitRemoteConnectionActions.Start).subscribe({
      next: async response => {
        if (response) {
          this.ui.showSpinner(this.translate.instant('s1.swal.connecting'));
          if (!refresh) {
            this.modal.open(S1ModalSizes.LG);
          }
          await this.cobrowseConnect(unit.code);
        } else {
          this.ui.showErrorPopup(this.translate.instant('units.cobrowse.popup.connectionError'));
        }
      },
      error: _ => {
        this.close();
      }
    });
  }

  private stopConnection(idUnit: number, skipCloseEvent?: boolean): void {
    this.isLoaded = false;
    this.device?.unsubscribe();
    this.unitsService.manageRemoteControl(idUnit, UnitRemoteConnectionActions.Stop).subscribe({
      next: response => {
        if (response) {
          this.cobrowseDisconnect();
          skipCloseEvent ? null : this.modal.close();
        } else {
          this.ui.showErrorPopup(this.translate.instant('error.generic_error'));
        }
      },
      error: _ => {
        this.ui.showErrorPopup(this.translate.instant('error.generic_error'));
      }
    });
  }

  /** Establishes the cobrowse connection getting the token from settings service */
  private async cobrowseConnect(codeUnit: string): Promise<void> {
    const url = await this.getCobrowseConnectionUrl(codeUnit);
    this.device?.subscribe();
    
    const deviceTimeout = setTimeout(() => {
      this.stopConnection(this.unit.id, true);
      this.unreachableDeviceError(true, this.translate.instant('units.cobrowse.popup.timeoutError'));
      this.isLoaded = true;
    }, 15000);

    this.device?.on('updated', (d) => {
      clearTimeout(deviceTimeout);
      if (d.connectable) {
        if (url) {
          this.iframeUrl = url;
          this.establishConnection();
        } else {
          this.unreachableDeviceError();
        }
      } else {
        this.unreachableDeviceError();
      }
    });
  }

  /** Returns the cobrowse connection url taking the device id (from the last version [index 0]) */
  private async getCobrowseConnectionUrl(codeUnit: string): Promise<SafeResourceUrl> {
    const parameters = {
      'nochrome': true,
      'end_action': 'none',
      'allow_popout': false,
      'token': this.token
    }

    const devices = await this.cobrowse.devices.list({filter_device_id: codeUnit});
    this.device = devices[0];

    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://cobrowse.io/connect/device/${this.device?.id}${this.s1HttpClient.mapParameters(parameters)}`);
  }

  /** Fires the cobrowse connection and starts the iframe */
  private async establishConnection(): Promise<void> {
    // attach to iframe (make sure it has loaded!)
    const frameEl = this.iframe.nativeElement;

    this.ctxCobrowse = await this.cobrowse.attachContext(frameEl);
    this.ctxCobrowse.on('session.updated', (session) => {
      if (session.ended) {
        if(this.ctxCobrowse) {
          this.close();
        }
      }
    });
    setTimeout(() => {
      //waiting for session
      this.ctxCobrowse.setTool('control');
      this.ui.closeSpinner();
      this.isLoaded = true;
    }, 5000);
  }

  private cobrowseDisconnect() {
    if(this.ctxCobrowse) {
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('javascript:false;');
      this.cobrowseContextDisconnect(this.ctxCobrowse);
    }
  }
  
  private async cobrowseContextDisconnect(ctx) {
    let response = {};
    if(ctx) {
      try {
        let isEnd = await ctx.endSession();
        response['success'] = isEnd;
        return response;
      }
      catch (err) {
        response['success'] = false;
        return response;
      }
    }
  }

  private unreachableDeviceError(skipCloseEvent?: boolean, alternativeError?: string): void {
    Swal.fire({
      icon: 'error',
      title: this.translate.instant('s1.swal.connectionError'),
      text: this.translate.instant(alternativeError ? alternativeError :'units.cobrowse.popup.unreachableDevice'),
      allowOutsideClick: false,
      showConfirmButton: true,
      ...(!skipCloseEvent) && { didClose: () => {
        this.close();
      } }
    });
  }

}
