
<div class="d-flex align-items-center">

  <button type="button" class="btn" (click)="toggle()">
    <i *ngIf="!check" class="far fa-square fa-2x" [ngClass]="classToApply"></i>
    <i *ngIf="check" class="fa fa-check-square text-success fa-2x" [ngClass]="classToApply"></i>
  </button> 
  @if(text != null) {
    <p class="ml-1 mb-0">{{ text }}</p>
  }
</div>