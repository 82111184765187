<h2 *ngIf="profileId" class="col-6 mt-4 mb-4 badge-profile">
    <em class="mr-1 icon-smartilio icon-{{profile?.nameType | lowercase}}" style="font-size:36px;"></em>
    <span>{{profile?.nameType}}</span>
</h2>

<form [formGroup]="profileNetworkForm">

    <div class="row text-left mt-2">
        <ng-container *ngIf="isCompanyUser()">
            <s1-input-text class="col-12" [control]="profileNetworkForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
            <mdm-input-region-select class="col-4" [control]="profileNetworkForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false"
                [siteControl]="profileNetworkForm.controls.site"></mdm-input-region-select>
            <mdm-input-site-select class="col-4" [control]="profileNetworkForm.controls.site" [idRegion]="profileNetworkForm.controls.region.value" [bindAllObject]="false"></mdm-input-site-select>
            <mdm-input-group-select class="col-4" [control]="profileNetworkForm.controls.group" [idCompany]="idCompany" [bindAllObject]="false"></mdm-input-group-select>
        </ng-container>

        <ng-container *ngIf="!isCompanyUser()">
            <s1-input-text class="col-12" [control]="profileNetworkForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
            <mdm-input-region-select class="col-4" [readonly]="profileId" [control]="profileNetworkForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false"
                [siteControl]="profileNetworkForm.controls.site"></mdm-input-region-select>
            <mdm-input-site-select class="col-4" [readonly]="profileId" [control]="profileNetworkForm.controls.site" [idRegion]="profileNetworkForm.controls.region.value" [bindAllObject]="false"></mdm-input-site-select>
            <mdm-input-group-select class="col-4" [readonly]="profileId" [control]="profileNetworkForm.controls.group" [idCompany]="idCompany" [bindAllObject]="false"></mdm-input-group-select>
        </ng-container>
    </div>


    <div class="row text-left mt-2">
        <s1-input-text class="col-4" [control]="profileNetworkForm.controls.ssid" [label]="'profiles.network.fields.ssid'" [labelGrouped]="false" [readonly]="readOnlyMode"></s1-input-text>
        <s1-input-switch class="col-auto" [control]="profileNetworkForm.controls.connectImmediately" [label]="'profiles.network.fields.connectImmediately'" [readonly]="readOnlyMode"></s1-input-switch>
    </div>
    <div class="row text-left mt-2">
        <mdm-input-security-network-type-select class="col-4" [readonly]="readOnlyMode" [control]="profileNetworkForm.controls.encryption" [bindAllObject]="false" (onSelectClear)="encryptionChange($event)" (onSelectChange)="encryptionChange($event)"></mdm-input-security-network-type-select>
        <ng-container *ngIf="!readOnlyMode; else pwdPlaceholder">
            <mdm-input-password class="col-4"
                [control]="profileNetworkForm.controls.password"
                [label]="'profiles.network.fields.password'"
                [editable]="!!profileId"
                (onPwdEdit)="changePassword = true">
            </mdm-input-password>
        </ng-container>
    </div>
    <div class="row text-left mt-2">
        <mdm-input-options-select class="col-4" [readonly]="readOnlyMode" [control]="profileNetworkForm.controls.ipstrategy" [label]="'profiles.network.fields.ipStrategy'" [options]="ipStrategies" [bindAllObject]="false"></mdm-input-options-select>
        <s1-input-switch class="col-auto" [control]="profileNetworkForm.controls.hidden" [label]="'profiles.network.fields.hidden'" [readonly]="readOnlyMode"></s1-input-switch>
    </div>

    <div class="row text-center mt-2 mb-1">
        <div class="col-12">
            <s1-button [disabled]="!profileNetworkForm.valid" [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
        </div>
    </div>

</form>

<ng-template #pwdPlaceholder>
    <mdm-input-placheholder class="col-4" [label]="'users.user.fields.password'"></mdm-input-placheholder>
</ng-template>