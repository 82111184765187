import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SummaryBadgeConfig } from '../summary-badge/summary-badge.component';
import { ChartPieConfig, ChartPieOptions } from '../chart-pie/chart-pie.component';

export interface StatisticCardConfig {
  title: string;
  badges: SummaryBadgeConfig[];
  chart: ChartPieConfig;
}

@Component({
  selector: 'statistic-card',
  templateUrl: './statistic-card.component.html',
  styleUrls: ['./statistic-card.component.scss']
})

export class StatisticCardComponent {

  @Input() title: string;
  @Input() badges: SummaryBadgeConfig[];
  @Input() chart: ChartPieConfig;
  @Input() padding = 0;
  @Input() options: ChartPieOptions;

  @Output() chartClicked = new EventEmitter<number>();

  constructor() {}

  elementSelected(index: number) {
    this.chartClicked.emit(index);
  }

}
