<s1-modal #modal [title]="'units.cobrowse.title' | translate: { brand: (unit?.nameBrand ? unit.nameBrand : ''), model: (unit?.nameModel ? unit.nameModel : ''), code:unit?.code }" (onClose)="close()">
  <s1-card>
    <div class="row justify-content-center pt-2">
      <div class="col-4 text-center">
        <s1-button [disabled]="!isLoaded" [type]="s1ButtonType.Normal" [icon]="'fa fa-sync-alt'" (onClick)="refresh()" [label]="'units.cobrowse.buttons.refresh'"></s1-button>
      </div>
    </div>
    <div class="embed-responsive embed-responsive-1by1">
      <iframe #iframe frameborder="1" width="100%" height="20rem" [src]="iframeUrl"></iframe>
    </div>
  </s1-card>
</s1-modal>