import { Component, ViewChild } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1ButtonType, S1Table } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { UnitsService } from '@app/shared/services/units.service';
import { IUnit } from '@app/shared/models/models';

@Component({
  selector: 'mdm-unit-assets-modal',
  templateUrl: './unit-assets-modal.component.html',
  styleUrls: ['./unit-assets-modal.component.scss']
})
export class UnitAssetsModalComponent implements IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  s1ButtonType = S1ButtonType;

  constructor(public settings: SettingsService, private unitsService: UnitsService) { }

  open(unit: IUnit) {
    this.modal.open(S1ModalSizes.XL);
    this.getUnitAssets(unit);
  }

  close() {
    this.modal.close();
  }

  getUnitAssets(unit: IUnit) {
    this.unitsService.getUnitAssets(unit).subscribe(assets => {
      this.table.updateData(assets);
    });
  }
  
  isType(assetType:string, type: string) {
    return assetType.toUpperCase() == type.toUpperCase();
  }

}
