import { Component, OnInit, Input, EventEmitter, ViewChild, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileStatus } from '@app/core/classes/status';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1Table, S1UIService } from '@app/s1';
import { IProfileSetting } from '@app/shared/models/profile';
import { ProfilesService } from '../../../shared/services/profiles.service';

@Component({
  selector: 'mdm-profile-settings-content',
  templateUrl: './profile-settings-content.component.html',
  styleUrls: ['./profile-settings-content.component.scss']
})
export class ProfileSettingsContentComponent implements OnInit {
  
  @Input() set profileSetting(newValue: IProfileSetting) {
    if (newValue) {
      this.settingProfile = newValue;

      if(!this.settingProfile.id) {
        this.showOnlyTableOthers = true;
        this.settingProfile.viewCanceledProfile = true;
      }

      if(this.settingProfile.viewCanceledProfile) {
        this.showTableHistory = false;
        this.showTableAssigned = false;
        this.showTableOthers = true;
        //detail not viewable
      }
      if(this.settingProfile.viewPendingProfile) {
        this.showTableHistory = false;
        this.showTableAssigned = true;
        this.showTableOthers = false;
        //update detail if not already selected
        if(this.hasProfileHistory && !this.selected && this.settingProfile.assigned?.length > 0) {
          this.viewAssigned(this.settingProfile.assigned[0]);
        } 
      } 
      if(!this.settingProfile.viewCanceledProfile && !this.settingProfile.viewPendingProfile) {
        this.showTableHistory = true;
        this.showTableAssigned = false;
        this.showTableOthers = false;
        //update detail if not already selected
        if(this.hasProfileHistory && !this.selected && this.settingProfile.history?.length > 0) {
          this.selected =  this.settingProfile.history[0];
        }
      }

      this.tableHistory.updateData(this.settingProfile.history);
      this.tableAssigned.updateData(this.settingProfile.assigned);
      this.tableOthers.updateData(this.settingProfile.canceled);
      this.updateForm();
    } else {
      this.selected = null;
    }
  }

  @Input() profileForm: UntypedFormGroup;
  @Input() hasProfileHistory: boolean = false;
  @Input() editMode: boolean = true;
  @Input() refreshButton: boolean = true;
  @Input() set profile(newValue: IProfileSetting) {
    if (newValue) {
      this.settingProfile = newValue;
      this.updateForm();
    }
  }

  @Output() onDetail = new EventEmitter<IProfileSetting>();
  @Output() onRefresh = new EventEmitter<any>();
  @Output() onChangeUnitProfileStatusInError = new EventEmitter<number>();
  
  @ViewChild('tableHistory') tableHistory: S1Table;
  @ViewChild('tableAssigned') tableAssigned: S1Table;
  @ViewChild('tableOthers') tableOthers: S1Table;

  settingProfile: IProfileSetting;
  selected: IProfileSetting;
  showTableHistory: boolean = true;
  showTableAssigned: boolean = false;
  showTableOthers: boolean = false;
  showOnlyTableOthers: boolean = false;
  settingsForm: UntypedFormGroup;
  s1ButtonType = S1ButtonType;
  profileStatus = ProfileStatus;
  parentPath: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ui: S1UIService,
    private profilesService: ProfilesService,
    private settings: SettingsService
  ) { 
    this.settingsForm = this.formBuilder.group({
      brightnessRadio: true,
      brightness: 50,
      barsVisibility: false,
      barVisibilityTop: false,
      barVisibilityBottom: false,
      screentimeoutRadio: true,
      screentimeout: 30,
      dailyReboot: false,
      dailyRebootTime: null,
      dailyRebootWhenCharging: false,
      locale: null,
      timezone: null,
      gpsInfo: false
    });

    this.settingsForm.controls.dailyReboot.valueChanges.subscribe(val => {
      if(!val) {
        this.settingsForm.controls.dailyRebootTime.patchValue(null);
        this.settingsForm.controls.dailyRebootWhenCharging.patchValue(null);
      }
    });
    
    this.settingsForm.controls.barsVisibility.valueChanges.subscribe(val => {
      this.settingsForm.controls.barVisibilityTop.patchValue(val);
      this.settingsForm.controls.barVisibilityBottom.patchValue(val);
    });
    
    this.settingsForm.controls.barVisibilityTop.valueChanges.subscribe(val => {
      if(this.settingsForm.controls.barsVisibility.value && !val && !this.settingsForm.controls.barVisibilityBottom.value) {
        this.settingsForm.controls.barsVisibility.patchValue(false);
      }
    });
    
    this.settingsForm.controls.barVisibilityBottom.valueChanges.subscribe(val => {
      if(this.settingsForm.controls.barsVisibility.value && !val && !this.settingsForm.controls.barVisibilityTop.value) {
        this.settingsForm.controls.barsVisibility.patchValue(false);
      }
    });

    this.route.parent.url.subscribe((urlPath) => {
      this.parentPath = "/" + urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit(): void { }

  refresh() {
    this.selected = null;
    this.onRefresh.emit(this.settingProfile.viewPendingProfile);
  }

  timezoneChange(type) {
    this.settingsForm.patchValue({
      timezone: type ? type.code: null
    });
  }

  localeChange(type) {
    this.settingsForm.patchValue({
      locale: type ? type.code: null
    });
  }

  stylizeStatus(status: string) {
    if (status === ProfileStatus.APPLIED) {
      return 'text-success';
    } else if (status === ProfileStatus.DOWNLOAD || status === ProfileStatus.ASSIGNED || status === ProfileStatus.SENT) {
      return 'text-warning';
    } else if (status === ProfileStatus.ERROR) {
      return 'text-danger';
    } else {
      return 'text-muted';
    }
  }
   
  askChangeProfileStatus(profile: IProfileSetting) {
    if(profile?.status ===  ProfileStatus.ASSIGNED || profile?.status ===  ProfileStatus.DOWNLOAD || profile?.status ===  ProfileStatus.SENT) {
      let text = 'profiles.distributions.popup.askChangeProfileStatusToCanceled'; //ProfileStatus.ASSIGNED
      if(profile?.status ===  ProfileStatus.DOWNLOAD) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToInterrupted';
      } else if(profile?.status ===  ProfileStatus.SENT) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToAborted';
      }
      this.ui.showDialogPopup(text).then(result => {
        if (result.value) {
          this.onChangeUnitProfileStatusInError.emit(profile.idUnitProfile);
        }
      });
    }
  }

  viewHistory(settingProfile?: IProfileSetting) {
    if(settingProfile) {
      this.selected = settingProfile;
    } else if(this.settingProfile.history.length > 0) {
      this.selected =  this.settingProfile.history[0];
    }
    this.selected.viewPendingProfile = this.settingProfile.viewPendingProfile;
    this.selected.viewCanceledProfile = false;
    this.onDetail.emit(this.selected);
  }

  viewAssigned(settingProfile?: IProfileSetting) {
    if(settingProfile) {
      this.selected = settingProfile;
    } else if(this.settingProfile.assigned.length > 0) {
      this.selected =  this.settingProfile.assigned[0];
    }
    this.selected.viewPendingProfile = this.settingProfile.viewPendingProfile;
    this.selected.viewCanceledProfile = false;
    this.onDetail.emit(this.selected);
  }

  viewOthers(settingProfile?: IProfileSetting) {
    if(settingProfile) {
      this.selected = settingProfile;
    } else if(this.settingProfile.canceled.length > 0) {
      this.selected =  this.settingProfile.canceled[0];
    }
    this.selected.viewPendingProfile = false;
    this.selected.viewCanceledProfile = this.settingProfile.viewCanceledProfile;
    this.onDetail.emit(this.selected);
  }

  viewLastApplied() {
    if(this.settingProfile.history?.length > 0) {
      this.selected =  this.settingProfile.history[0];
      this.selected.viewPendingProfile = this.settingProfile.viewPendingProfile;
      this.selected.viewCanceledProfile = false;
    }
    this.tableHistory.config.pagination.page.page=1;
    this.tableHistory.pagination.changePage();
    this.onDetail.emit(this.selected);
  }

  openHistoryTable() {
    this.showTableOthers = false;
    this.showTableHistory = true;
    this.showTableAssigned = false;
    this.settingProfile.viewPendingProfile = false;
    this.viewHistory();
  }

  openAssignedTable() {
    if(this.settingProfile.assigned?.length > 0) {
      this.showTableOthers=false;
      this.showTableHistory=false;
      this.showTableAssigned=true;
      this.settingProfile.viewPendingProfile = true;
      this.selected.viewCanceledProfile = false;
      this.viewAssigned();
    }
  }

  openOthersTable() {
    if(this.tableOthers.config.rows.length > 0) {
      this.showTableOthers = true;
      this.showTableHistory = false;
      this.showTableAssigned = false;
      this.settingProfile.viewPendingProfile = false;
      this.settingProfile.viewCanceledProfile = true;
      this.viewOthers();
    }
  }

  // ---------- PRIVATE METHODS ---------- //

  private updateForm() {
    
    let datetime: any;
    if(this.editMode && this.settingProfile?.dailyRebootTime) { //editMode get date type, readMode get string type
      datetime = new Date();
      let parts = this.settingProfile?.dailyRebootTime.split(':');
      datetime.setHours(parseInt(parts[0]), parseInt(parts[1]), parseInt(parts[2]));
    } else {
      datetime = this.settingProfile?.dailyRebootTime;
    }

    this.settingsForm?.patchValue({
      brightnessRadio: this.settingProfile?.brightness!=null ? true: false,
      brightness: this.settingProfile?.brightness || 0,
      barsVisibility: this.settingProfile?.barsVisibility,
      barVisibilityTop: this.settingProfile?.barsVisibility && this.settingProfile?.barVisibilityTop,
      barVisibilityBottom: this.settingProfile?.barsVisibility && this.settingProfile?.barVisibilityBottom,
      screentimeoutRadio: (this.settingProfile?.screentimeout && this.settingProfile?.screentimeout > 0) ? true: false,
      screentimeout: this.settingProfile?.screentimeout,
      dailyReboot: this.settingProfile?.dailyReboot,
      dailyRebootTime: datetime,
      dailyRebootWhenCharging: this.settingProfile?.whenCharging,
      locale: this.settingProfile?.locale,
      timezone: this.settingProfile?.timezone,
      gpsInfo: this.settingProfile?.gpsInfo
    })
  }

}