{
  "outcome": {
      "success": true,
      "code": null,
      "message": null,
      "description": null
  },
  "data": {
      "results": [
          {
              "idProfile":11,
              "codStatistic": "DEVICE_ACTIVATING",
              "partial": 2,
              "total": 10
          },{
              "idProfile":11,
              "codStatistic": "DEVICE_WAITING_FOR_ACTIVATION",
              "partial": 3,
              "total": 10
          },{
              "idProfile":11,
              "codStatistic": "DEVICE_NOT_SCHEDULATED",
              "partial": 4,
              "total": 10
          },{
              "idProfile":11,
              "codStatistic": "DEVICE_IN_ERROR",
              "partial": 1,
              "total": 10
          }
      ],
      "total": 4
  }
}